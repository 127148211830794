/*
 * File: user.type.ts
 * Project: autobuy-back-office
 * File Created: Friday, 23rd June 2023 11:19:21
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Tuesday, 16th January 2024 12:17:55
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

import { CompanyDataType } from "./company.type"

export type UserIntel = {
    firstName?: string,
    lastName?: string,
    email?: string,
    phone?: string,
    cellPhone?: string,
    language?: string,
    identityFile?: File,
    // address?: Address
}
export type Address = {
    address: string,
    city: string,
    postalCode: string,
    state: string,
    country: string
} | undefined

export type Permission = {
    id: string,
    ressourceName: string,
    create: boolean,
    read: boolean,
    update: boolean,
    delete: boolean
}

export type UserType = {
    id: string,
    lastName: string,
    firstName: string,
    role: string,
    permissions: string
}

export type UpdateUserType = {
    Email?: string,
    CommercialID?: string,
    FlyingCommercialID?: string,
    LastName?: string,
    FirstName?: string,
    Phone?: string,
    CellPhone?: string,
    Address?: string,
    City?: string,
    PostalCode?: string,
    State?: string,
    Country?: string,
    Comments?: string,
    CompanyID?: string,
}

export enum UserStatus {
    NEW="NEW",
    PENDING="PENDING",
    VALID="VALID",
    ARCHIVED="ARCHIVED"
}

export type UpdateUserIntel = {
    update: UpdateUserType,
    add: {groups: string[], parameters: string[]},
    remove: {groups: string[], parameters: string[]}
}

export type UserSimple = {
    id: string;
    LastName: string;
    FirstName: string;
}

export type UserParametersType = {
    id: string,
    UpdatedAt: Date,
    DailyReport: boolean,
    EndSellReminder: boolean,
    FavoriteReminder: boolean,
    SellInvitation: boolean,
    CarReserved: boolean,
    CarBought: boolean,
    EndSellReport: boolean,
    OfferReport: boolean,
    MarketCommunication: boolean
}

enum SigningState {
    Ongoing="Ongoing",
    Declined="Declined",
    Expired="Expired",
    Canceled="Canceled",
    Done="Done"
}

type CGVSigning = {
    id: string,
    CreatedAt: Date,
    Link: string,
    SRID: string,
    ExpireDate: Date,
    State: SigningState,
    UserID: string,
}

export type UserSingleType = {
    id: string;
    CreatedAt: string;
    UpdatedAt: string;
    LastName: string;
    FirstName: string;
    Email: string;
    Address: string;
    City: string;
    PostalCode: string;
    State: string;
    Country: string;
    Phone: string;
    Comments: string;
    CellPhone: string;
    CommercialID: string| null;
    FlyingCommercialID: string| null;
    DuoCommercialID: string| null;
    Status: string;
    LanguageID: string;
    CompanyID: string;
    ConnexionList: {
        id: string;
        UserID: string;
        Date: string;
        IP: string;
        From: string;
        Status: string;
    }[];
    UserGroup: [];
    Company: CompanyDataType;
    CGVRequests: CGVSigning[]
};

export type UserCreationIntel = {
    Groups: string[],
    LastName: string,
    FirstName: string,
    Email: string,
    Address: string | undefined,
    City: string | undefined,
    PostalCode: string,
    State: string | undefined,
    Country: string | undefined,
    LanguageID: string,
    Role: string,
    Phone: string | undefined,
    CellPhone: string | undefined,
    Password: string | undefined,
    CommercialID?: string,
    FlyingCommercialID?: string,
    IDFile?: File,
    Company: {
        id?: string,
        Name?: string,
        Siren?: string,
        Country?: string,
        KBIS?: File,
        StandardPhoneNumber?: string,
        Address?: string,
        City?: string,
        PostalCode?: string,
        State?: string
    },
}

export type UserData = {
    Company: {
        Name: string;
    };
    id: string;
    CellPhone: string;
    Address: string;
    City: string;
    CommercialID: string | null;
    Commercial: UserSimple;
    ConnexionList: any[];
    Country: string;
    Email: string;
    FirstName: string;
    LastName: string;
    State: string;
    FlyingCommercialID: string | null;
    Offer: any[];
    PostalCode: string;
    UserRole?: string;
    SeenCar: any[];
    Status: string;
    UserGroup: any[];
};

export type LoadedUsersData = {valid: {nb_page: number, myUsers: UserData[]}, notValid: {nb_page: number, myUsers: UserData[]}, archived: {nb_page: number, myUsers: UserData[]}};


export type LoadedMyUsersData = {nb_page: number, myUsers: UserData[]};

