/*
 * File: HomeService.ts
 * Project: autobuy-back-office
 * File Created: Tuesday, 26th September 2023 03:45:15
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Wednesday, 27th September 2023 09:27:11
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

import Config from "Config/Config";
import RouterPathResolver from "Config/Router/RouterPathResolver";
import CarService from "Services/Car/CarService";
import CarrierService from "Services/Carrier/CarrierService";
import EmailService from "Services/Email/EmailService";
import GroupService from "Services/GroupService/GroupService";
import PartnerService from "Services/PartnerService/PartnerService";
import ServiceGeneral from "Services/ServiceGeneral";
import UserService from "Services/Users/UserService";
import { buildAxiosHeader } from "Utils/Request.utils";
import axios from "axios";

class HomeService {
    public async getCarsInfo(logout: Function) {
        const carsInfo = await ServiceGeneral.manageAxiosResponse( () => { return CarService.getCarInfo() }, () => logout());
        carsInfo.title = "cars";
        carsInfo.total = carsInfo.sold + carsInfo.archived + carsInfo.selling + carsInfo.reserved;
        carsInfo.path = RouterPathResolver.pathCar.path;
        return carsInfo;
    }

    public async getUsersInfo(logout: Function) {
        return ServiceGeneral.manageAxiosResponse( () => { return UserService.getUsersInfo()}, () => logout());
    }

    // public async getGroupsInfo(logout: Function) {
    //     return ServiceGeneral.manageAxiosResponse( () => { return GroupService.getGroupsInfo()}, () => logout());
    // }

    // public async getPartnersInfo(logout: Function) {
    //     return ServiceGeneral.manageAxiosResponse( () => { return PartnerService.getPartnersInfo()}, () => logout());
    // }

    // public async getCarriersInfo(logout: Function) {
    //     return ServiceGeneral.manageAxiosResponse( () => { return CarrierService.getCarriersInfo()}, () => logout());
    // }

    private async getConnexionsInfo(logout: Function) {
        const url = `${Config.BACKEND.BASEURL}${Config.BACKEND.PATHS.CONNEXIONINFO}`;
        return await ServiceGeneral.manageAxiosResponse( async () => { return await axios.get(url,  buildAxiosHeader())}, () => logout());
    }

    private async getSeenCarsInfo(logout: Function) {
        const url = `${Config.BACKEND.BASEURL}${Config.BACKEND.PATHS.SEENCARINFO}`;
        return await ServiceGeneral.manageAxiosResponse(async () => { return await axios.get(url,  buildAxiosHeader())}, () => logout());
    }

    private async getOfferInfo(logout: Function) {
        const url = `${Config.BACKEND.BASEURL}${Config.BACKEND.PATHS.OFFERCARINFO}`;
        return await ServiceGeneral.manageAxiosResponse(async () => { return await axios.get(url,  buildAxiosHeader())}, () => logout());
    }

    private async getEmailInfo(logout: Function) {
        const url = `${Config.BACKEND.BASEURL}${Config.BACKEND.PATHS.TIPIMAIL}`;

        const day = await ServiceGeneral.manageAxiosResponse(async () => { return await EmailService.getAll({from: "day"})}, () => logout());
        const week = await ServiceGeneral.manageAxiosResponse(async () => { return await EmailService.getAll({from: "week"})}, () => logout());
        const month = await ServiceGeneral.manageAxiosResponse(async () => { return await EmailService.getAll({from: "month"})}, () => logout());
        return {day, week, month};
    }

    public async getUsageInfo(logout: Function) {
        const connexionInfo = (await this.getConnexionsInfo(logout)).data;
        const seenCarsInfo = (await this.getSeenCarsInfo(logout)).data;
        const offerInfo = (await this.getOfferInfo(logout)).data;
        const emailInfo = (await this.getEmailInfo(logout));
        return {connexions: connexionInfo, seenCars: seenCarsInfo, offers: offerInfo, email: emailInfo};
    }
}

export default new HomeService();
